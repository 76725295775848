import React, { Component } from 'react';
import './Footer.css';

class Footer extends Component {
  render() {
    return (
      <div id="footer" className="footer">
            <h5>Copyright © Brady Trudeau 2020</h5>
      </div>
    );
  }
}

export default Footer;